import theme from '@client/css-modules/TrackOrBuyHomeLayout.css';
import { View } from '@client/routes/constants';
import classNames from 'classnames';
import LinkButton from '@client/components/generic/LinkButton';
import HomeSubpageMotionDiv from '@client/components/HomeSubpageMotionDiv';

type Props = {
  title: string;
  buttonText: string;
  ctaLink: View;
  // Whether the layout has the image on the left or right. Not applicable to mobile
  desktopImagePosition?: 'left' | 'right';
  image: string;
  imageAlt: string;
  // A delay applied to start of animation so that
  // multiples of this component can be staggered.
  animationDelay?: number;
  onClick?: () => void;
  // Will be placed before data-hc-name tags for automation testing
  dataHcPrefix: string;
  animate: {
    y: number;
    opacity: number;
  };
  children?: React.ReactNode;
};

const TrackOrBuyHomeLayout: React.FC<Props> = ({
  title,
  buttonText,
  children,
  ctaLink,
  desktopImagePosition,
  image,
  imageAlt,
  animationDelay,
  dataHcPrefix,
  animate,
  onClick,
}) => {
  const isDesktopImageOnLeft = desktopImagePosition === 'left';

  const createDataHcName = (suffix: string) => {
    const prefixEndsInDash =
      dataHcPrefix.charAt(dataHcPrefix.length - 1) === '-';
    return `${dataHcPrefix}${prefixEndsInDash ? '' : '-'}${suffix}`;
  };

  return (
    <HomeSubpageMotionDiv
      initial={{ y: 0 }}
      animate={animate}
      transition={{
        duration: 0.1,
        delay: animationDelay || 0,
        type: 'spring',
        mass: 0.1,
        damping: 18,
        stiffness: 250,
      }}
      className={classNames(theme.TrackOrBuyHomeLayout, {
        [theme.ImageLeft]: isDesktopImageOnLeft,
      })}
    >
      <div className={theme.TextContent}>
        <h2 className={theme.Title} data-hc-name={createDataHcName('header')}>
          {title}
        </h2>
        <p
          className={theme.Content}
          data-hc-name={createDataHcName('description')}
        >
          {children}
        </p>
        <LinkButton
          className={theme.CTAButton}
          ariaLabel={buttonText}
          view={ctaLink}
          onClick={onClick}
          dataHcName={createDataHcName('button')}
        >
          {buttonText}
        </LinkButton>
      </div>
      <div className={theme.ImageColumn}>
        <img className={theme.Image} alt={imageAlt} src={image} />
      </div>
    </HomeSubpageMotionDiv>
  );
};

export default TrackOrBuyHomeLayout;
