import { MotionValue, useTransform } from 'framer-motion';
import { RefObject } from 'react';

/**
 * Provides common animation metrics support for elements used within Homepage subpages
 */
export function useHomepageElementAnimationMetrics(
  subpageRef: RefObject<HTMLDivElement>,
  scrollPosition: MotionValue<number>,
  startingPositionOffset: number
) {
  const scrollPositionSteps = [
    0,
    (subpageRef.current?.offsetTop || 0) - 400,
    (subpageRef.current?.offsetTop || 0) - 350,
    subpageRef.current?.offsetTop || 0,
    (subpageRef.current?.offsetTop || 0) + 50,
    (subpageRef.current?.offsetTop || 0) + 100,
  ];
  const y = useTransform(scrollPosition, scrollPositionSteps, [
    -startingPositionOffset,
    -startingPositionOffset,
    0,
    0,
    0,
    +startingPositionOffset / 5,
  ]);

  const opacity = useTransform(
    scrollPosition,
    scrollPositionSteps,
    [0, 0, 1, 1, 1, 0]
  );
  return { opacity: opacity.get(), y: y.get() };
}
