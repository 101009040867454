import TrackOrBuyHomeLayout from '@client/components/TrackOrBuyHomeLayout';
import theme from '@client/css-modules/HomeSubpageTrackOrBuyHome.css';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { MotionValue } from 'framer-motion';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BuyHomeImage from '@client/assets/images/homepage-track-home-1.jpg';
import ClaimHomeImage from '@client/assets/images/homepage-track-home-2.jpg';
import { useHomepageElementAnimationMetrics } from '@client/hooks/use-homepage-element-animation-metrics.hooks';
import { selectHomepageConfig } from '@client/store/selectors/cobranding.selectors';

type Props = {
  scrollPosition: MotionValue<number>;
};

const STARTING_POSITION_OFFSET = 250;
const HomeSubpageTrackOrBuyHome: React.FC<Props> = ({ scrollPosition }) => {
  const dispatch = useDispatch();
  const isHomeownerEnabled = useSelector(getIsFeatureEnabled('homeowner'));
  const {
    homebuyingCtaHeader,
    homebuyingCtaDescription,
    homebuyingCtaButtonText,
    homeownerCtaHeader,
    homeownerCtaDescription,
    homeownerCtaButtonText,
  } = useSelector(selectHomepageConfig);
  const ref = useRef<HTMLDivElement | null>(null);
  const { y, opacity } = useHomepageElementAnimationMetrics(
    ref,
    scrollPosition,
    STARTING_POSITION_OFFSET
  );
  const animate = {
    y,
    opacity,
  };

  const searchHomesTitle = homebuyingCtaHeader || 'Buying a home';
  const searchHomesButtonText = homebuyingCtaButtonText || 'Search homes';
  const searchHomesDescription =
    homebuyingCtaDescription ||
    'Search homes for sale and filter by price, neighborhood, school ratings, and more. Find the perfect home that fits your needs.';
  const homeownerTitle = homeownerCtaHeader || 'Your homeowner dashboard';
  const homeownerButtonText = homeownerCtaButtonText || 'See my home';
  const homeownerDescription =
    homeownerCtaDescription ||
    "See your home's value, equity, and what a home renovation would do to your value. Claim your home and access these features and more.";

  return (
    <div className={theme.HomeSubpageTrackOrBuyHome} ref={ref}>
      <div className={theme.HighZoomScrollableWrapper}>
        <TrackOrBuyHomeLayout
          animate={animate}
          dataHcPrefix="buy-home-modal"
          animationDelay={0.2}
          title={searchHomesTitle}
          buttonText={searchHomesButtonText}
          ctaLink={View.SEARCH}
          image={BuyHomeImage}
          imageAlt="A building"
          onClick={() =>
            dispatch(
              reportEvent('click_cta_homepage_buyer', PARENT_EVENTS.CLICK_CTA)
            )
          }
        >
          {searchHomesDescription}
        </TrackOrBuyHomeLayout>
        {isHomeownerEnabled && (
          <TrackOrBuyHomeLayout
            animate={animate}
            dataHcPrefix="your-home-dash-modal"
            animationDelay={0.3}
            title={homeownerTitle}
            buttonText={homeownerButtonText}
            ctaLink={View.HOMEOWNER}
            desktopImagePosition="left"
            image={ClaimHomeImage}
            imageAlt="A house"
            onClick={() =>
              dispatch(
                reportEvent('click_cta_homepage_owner', PARENT_EVENTS.CLICK_CTA)
              )
            }
          >
            {homeownerDescription}
          </TrackOrBuyHomeLayout>
        )}
      </div>
    </div>
  );
};

export default HomeSubpageTrackOrBuyHome;
