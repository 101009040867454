import composeRefs from '@seznam/compose-react-refs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import HomepageArrowNav from '@client/components/HomepageArrowNav';
import HomeSubpageSearch from '@client/components/HomeSubpageSearch';
import HomeSubpageTrackOrBuyHome from '@client/components/HomeSubpageTrackOrBuyHome';
import HomeSubpageYourTeamAgent from '@client/components/HomeSubpageYourTeamAgent';
import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import FooterContainer from '@client/containers/footer.container';
import HeaderContainer from '@client/containers/header.container';
import theme from '@client/css-modules/Homepage.css';
import { HEADER_FOCUS_STATE_IDS } from '@client/store/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getIsFourHundredZoom,
  getIsSmallSize,
} from '@client/store/selectors/match-media.selectors';
import { useScroll } from 'framer-motion';
import { useSelector } from 'react-redux';
type SubPages = {
  [key: string]: JSX.Element | null;
};

type SubpageProps = {
  onInView: () => void;
  parentEle: HTMLDivElement | null;
  forwardRef: (ele: HTMLElement | null) => void;
  children?: React.ReactNode;
  isFourHundredZoom?: boolean;
};

const Subpage: React.FC<SubpageProps> = ({
  onInView,
  children,
  parentEle,
  forwardRef,
  isFourHundredZoom,
}) => {
  /* `inView` will change from false to true whenever the subpage enters the viewport */
  const { ref, inView } = useInView({
    root: parentEle,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      onInView();
    }
  }, [inView]);

  const subpageRef = useCallback((ele: HTMLElement | null) => {
    forwardRef(ele);
    ref(ele);
  }, []);

  return (
    <div
      className={theme.Subpage}
      ref={subpageRef}
      style={
        !isFourHundredZoom
          ? {
              height:
                '100%' /* Defining inline so that "in view" tracking works in both dev and prod builds */,
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};

const HomeSubpageFooter = () => (
  <div className={theme.SubpageFooter}>
    <HorizontalSeparator theme={theme} />
    <FooterContainer theme={theme} shouldUseSectionElement />
  </div>
);

const Homepage: React.FC<{}> = ({}) => {
  /* TODO: `parconSubpageIds` will be an array of page ID's pulled from Parcon to
   * show what the client has selected to display and in what order. */
  const parconSubpageIds = [
    'search',
    'track-or-buy',
    'your-team-agent',
    'footer',
  ];
  const [currentSubpageIndex, setCurrentSubpageIndex] = useState<number>(0);
  const [scrollSnapWrapperEle, setScrollSnapWrapperEle] =
    useState<HTMLDivElement | null>(null);
  const isSmallSize = useSelector(getIsSmallSize);
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const isFourHundredZoom = useSelector(getIsFourHundredZoom);
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const scrollSnapWrapperRefObj = useRef<HTMLDivElement | null>(null);
  const subpageRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const { scrollY } = useScroll({ container: scrollSnapWrapperRefObj });
  /* Note: `scrollY` doesn't change in a way that allows this component to re-render.  Instead, we
   * need to use the Motion `.onChange` handler to call `setScrollPosition` to cause this component
   * to re-render to get the updated `scrollY` Motion object into child components */
  const [_, setScrollPosition] = useState(0);
  /* Note: page order is determined by parconSubpageIds array */
  const subpages: SubPages = {
    search: (
      <HomeSubpageSearch
        scrollPosition={scrollY}
        scrollableParentHeight={scrollSnapWrapperEle?.clientHeight || null}
      />
    ),
    footer: <HomeSubpageFooter />,
    'track-or-buy': <HomeSubpageTrackOrBuyHome scrollPosition={scrollY} />,
    'your-team-agent':
      isReferralServicesEnabled && isYourTeamEnabled ? (
        <HomeSubpageYourTeamAgent scrollPosition={scrollY} />
      ) : null,
  };

  const subpageIdsForDisplay = parconSubpageIds.filter(
    (subpageId) => subpages[subpageId]
  );

  /**
   * Clicking the nav allows jumping to one of the subpages
   */
  const handleNavClick = (index: number) => {
    const soughtSubpageKey = subpageIdsForDisplay[index];
    const soughtSubpage = subpageRefs.current[soughtSubpageKey];

    if (soughtSubpage) {
      soughtSubpage.scrollIntoView({ behavior: 'smooth' });
    } else {
      throw new Error(
        `Can't find subpage with key ${soughtSubpageKey} to scroll`
      );
    }
  };

  /**
   * After the page enters the viewport, update the subpage index
   */
  const handleSubpageInView = (index: number) => {
    setCurrentSubpageIndex(index);
  };

  /**
   * Cache the ref of all subpages so that we can scroll them into view on nav click
   */
  const cacheSubpageRef = (subpageId: string, ele: HTMLElement | null) => {
    if (ele) {
      subpageRefs.current[subpageId] = ele;
    }
  };

  /**
   * Necessary for component to re-render when the scroll position changes (causing the updated scroll
   * position to be passed to child components)
   */
  useEffect(() => {
    const unsubscribe = scrollY.on('change', setScrollPosition);

    return () => {
      unsubscribe();
    };
  }, []);

  /**
   * Prevent the <body> from being scrollable while on the Homepage to fix iOS issue where it thinks
   * the user might be trying to scroll the body when scrolling up at the very top of the page
   */
  useEffect(() => {
    const previousOverflowValue = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = previousOverflowValue;
    };
  }, []);

  const scrollSnapWrapperCallbackRef = (ele: HTMLDivElement | null) => {
    if (ele) {
      setScrollSnapWrapperEle(ele);
    }
  };

  return (
    <>
      <AccessiblePageTitleContainer />
      <div className={theme.Homepage}>
        <header className={theme.HeaderWrapper}>
          <HeaderContainer />
        </header>
        <div className={theme.SubpageWrapper}>
          <div
            className={theme.ScrollSnapWrapper}
            id={HEADER_FOCUS_STATE_IDS.HOMEPAGE}
            ref={composeRefs(
              scrollSnapWrapperRefObj,
              scrollSnapWrapperCallbackRef
            )}
          >
            {subpageIdsForDisplay.map((subpageId, subpageIndex) => (
              <Subpage
                key={subpageId}
                parentEle={scrollSnapWrapperRefObj.current}
                forwardRef={(ele: HTMLElement | null) => {
                  cacheSubpageRef(subpageId, ele);
                }}
                onInView={() => handleSubpageInView(subpageIndex)}
                isFourHundredZoom={isFourHundredZoom}
              >
                {subpages[subpageId]}
              </Subpage>
            ))}
          </div>
          {isSmallSize && currentSubpageIndex !== 0 && !isFourHundredZoom && (
            <HomepageArrowNav
              onArrowClick={handleNavClick}
              selectedIndex={currentSubpageIndex}
              isOnDarkBg={false}
              isAtEnd={currentSubpageIndex === subpageIdsForDisplay.length - 1}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Homepage;
