import classNames from 'classnames';
import { MotionValue, useTransform } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HomeImage1 from '@client/assets/images/homepage-house-1.jpg';
import HomeImage10 from '@client/assets/images/homepage-house-10.jpg';
import HomeImage2 from '@client/assets/images/homepage-house-2.jpg';
import HomeImage3 from '@client/assets/images/homepage-house-3.jpg';
import HomeImage4 from '@client/assets/images/homepage-house-4.jpg';
import HomeImage5 from '@client/assets/images/homepage-house-5.jpg';
import HomeImage6 from '@client/assets/images/homepage-house-6.jpg';
import HomeImage7 from '@client/assets/images/homepage-house-7.jpg';
import HomeImage8 from '@client/assets/images/homepage-house-8.jpg';
import HomeImage9 from '@client/assets/images/homepage-house-9.jpg';
import HomeSubpageMotionDiv from '@client/components/HomeSubpageMotionDiv';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import theme from '@client/css-modules/HomeSubpageSearch.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useIsMounted } from '@client/hooks/use-is-mounted.hooks';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { searchFieldClick } from '@client/store/actions/search.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  getIsWellsFargoCobrand,
  selectHomepageConfig,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import * as Tabs from '@radix-ui/react-tabs';

const HOME_IMAGES = [
  HomeImage1,
  HomeImage2,
  HomeImage3,
  HomeImage4,
  HomeImage5,
  HomeImage6,
  HomeImage7,
  HomeImage8,
  HomeImage9,
  HomeImage10,
];

const PHOTO_COLUMN_Y_OFFSCREEN_BUFFER = 100;
const PHOTO_EVEN_COLUMN_MOVEMENT_OFFSET = 800;

const HOMEPAGE_COPY_LIST = {
  FIND_A_HOME_COPY: {
    header: <>Find your dream home</>,
    subHeader:
      "Search homes in your neighborhood and find a house that's right for you.",
  },
  TRACK_MY_HOME_COPY: {
    header: (
      <>
        See your home's <br />
        full potential
      </>
    ),
    subHeader:
      "Claim your home and unlock features to see your home's value, equity, and more.",
  },
  // to be used only for wells
  FIND_A_HOME_COPY_WELLS: {
    subHeader:
      "Search in your neighborhood and find a home that's right for you.",
  },
};

const PhotoColumn: React.FC<{
  animate: { opacity: number; y: number };
  urls: string[];
  urlsToRender: string[];
}> = ({ animate, urls, urlsToRender }) => {
  return (
    <HomeSubpageMotionDiv
      initial={{ y: 0 }}
      animate={animate}
      transition={{
        duration: 0.1,
        delay: 0,
        type: 'spring',
        mass: 0.1,
        damping: 18,
        stiffness: 250,
      }}
      className={theme.PhotoColumn}
    >
      {urls.map((url, idx) => {
        const shouldDisplayImage = urlsToRender.includes(url);
        return (
          <HomeSubpageMotionDiv
            animate={{ opacity: shouldDisplayImage ? 1 : 0 }}
            initial={{ opacity: 0 }}
            key={`photo_${idx}`}
            className={theme.PhotoColumnPhoto}
            style={{ backgroundImage: `url(${url})` }}
          />
        );
      })}
    </HomeSubpageMotionDiv>
  );
};

type Props = {
  scrollPosition: MotionValue<number>;
  scrollableParentHeight: number | null;
};

const HomeSubpageSearch: React.FC<Props> = ({
  scrollPosition,
  scrollableParentHeight,
}) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const imagePreloadRefs = useRef<{ [key: string]: HTMLImageElement }>({});
  const [photoSectionEle, setPhotoSectionEle] = useState<HTMLDivElement | null>(
    null
  );
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [preloadedImageUrls, setPreloadedImageUrls] = useState<string[]>([]);
  const isShowingHomeownerSearch = useSelector(
    getIsFeatureEnabled('homeowner')
  );
  const isCobrandWellsFargo = useSelector(getIsWellsFargoCobrand);
  const isSmallSize = useSelector(getIsSmallSize);
  const {
    homebuyingHeaderText,
    homebuyingSubheaderText,
    homebuyingSearchTabLabel,
    homebuyingSearchInputPlaceholder,
    homeownerHeaderText,
    homeownerSubheaderText,
    homeownerSearchTabLabel,
    homeownerSearchInputPlaceholder,
  } = useSelector(selectHomepageConfig);
  const { activeOptionUnderlineColor } = useCobrandStyles();
  const [searchType, setSearchType] = useState<string>('FIND_A_HOME_TYPE');
  const parentHeight = scrollableParentHeight || 0;
  const photoSectionHeight = photoSectionEle ? photoSectionEle.clientHeight : 0;
  const photoSectionOffsetTop = photoSectionEle ? photoSectionEle.offsetTop : 0;
  const photoColumnEndY =
    parentHeight - photoSectionOffsetTop - photoSectionHeight;
  const yPhotoColumnSlower = useTransform(
    scrollPosition,
    [0, parentHeight],
    [0, parentHeight ? photoColumnEndY - PHOTO_COLUMN_Y_OFFSCREEN_BUFFER : 0]
  );
  const yPhotoColumnFaster = useTransform(
    scrollPosition,
    [0, parentHeight],
    [
      0,
      parentHeight
        ? photoColumnEndY -
          PHOTO_COLUMN_Y_OFFSCREEN_BUFFER -
          PHOTO_EVEN_COLUMN_MOVEMENT_OFFSET
        : 0,
    ]
  );
  const opacityPhotoColumns = useTransform(
    scrollPosition,
    [0, parentHeight - 300, parentHeight],
    [1, 1, 0]
  );
  const opacityBrokerAttr = useTransform(scrollPosition, [0, 30], [1, 0]);
  const opacityTopSection = useTransform(scrollPosition, [0, 30], [1, 0]);
  const animatePhotoColumnSlower = {
    opacity: opacityPhotoColumns.get(),
    y: yPhotoColumnSlower.get(),
  };
  const animatePhotoColumnFaster = {
    opacity: opacityPhotoColumns.get(),
    y: yPhotoColumnFaster.get(),
  };
  const animateTopSection = {
    opacity: opacityTopSection.get(),
  };
  const animateBrokerAttr = {
    opacity: opacityBrokerAttr.get(),
  };
  const isHidingHeader = isSmallSize && isInputFocused;

  const homePageHeader =
    searchType === 'FIND_A_HOME_TYPE'
      ? homebuyingHeaderText || HOMEPAGE_COPY_LIST.FIND_A_HOME_COPY.header
      : homeownerHeaderText || HOMEPAGE_COPY_LIST.TRACK_MY_HOME_COPY.header;

  const homePageSubHeader =
    searchType === 'FIND_A_HOME_TYPE'
      ? isCobrandWellsFargo
        ? HOMEPAGE_COPY_LIST.FIND_A_HOME_COPY_WELLS.subHeader
        : homebuyingSubheaderText ||
          HOMEPAGE_COPY_LIST.FIND_A_HOME_COPY.subHeader
      : homeownerSubheaderText ||
        HOMEPAGE_COPY_LIST.TRACK_MY_HOME_COPY.subHeader;

  const homePageSearchPageLabel = homebuyingSearchTabLabel || 'Find a home';
  const homePageHomeownerPageLabel = homeownerSearchTabLabel || 'My home value';
  const homePagePlaceholder =
    searchType === 'TRACK_MY_HOME_TYPE'
      ? homeownerSearchInputPlaceholder || 'Enter your home address'
      : homebuyingSearchInputPlaceholder || undefined;

  useEffect(() => {
    HOME_IMAGES.forEach((url) => {
      imagePreloadRefs.current[url] = new Image();
      imagePreloadRefs.current[url].addEventListener('load', () => {
        setPreloadedImageUrls((oldValue) => [...oldValue, url]);
      });
      imagePreloadRefs.current[url].src = url;
    });

    return () => {
      /* Delete the image instances, causing garbage collection to remove the event listeners */
      HOME_IMAGES.forEach((url) => {
        delete imagePreloadRefs.current[url];
      });
    };
  }, []);

  const setPhotoSectionEleCallback = useCallback((ele) => {
    if (ele) {
      setPhotoSectionEle(ele);
    }
  }, []);

  return (
    <div className={theme.HomeSubpageSearch}>
      <HomeSubpageMotionDiv
        initial={{ opacity: 1 }}
        animate={animateTopSection}
        className={classNames(theme.TopSection, {
          [theme.TopSectionWithNoSelector]: !isShowingHomeownerSearch,
        })}
        style={isHidingHeader ? { paddingTop: 0 } : {}}
      >
        {!isHidingHeader && (
          <div className={theme.HeaderSection}>
            {homeownerHeaderText && homebuyingHeaderText ? (
              <h1
                className={theme.Header}
                // these values come from Parcon and can contain HTML in the string
                dangerouslySetInnerHTML={{ __html: homePageHeader }}
              />
            ) : (
              <h1 className={theme.Header}>{homePageHeader}</h1>
            )}
            <div className={theme.SubHeader}>{homePageSubHeader}</div>
          </div>
        )}
        {isShowingHomeownerSearch && (
          <Tabs.Root
            activationMode="manual"
            defaultValue="FIND_A_HOME_TYPE"
            onValueChange={(valueToChange: string) => {
              setSearchType(valueToChange);
            }}
          >
            <Tabs.List className={theme.SearchTypeSelector}>
              <Tabs.Trigger
                id="FIND_A_HOME_TYPE"
                value="FIND_A_HOME_TYPE"
                data-hc-name="find-a-home"
                aria-controls="HOME_SUBPAGE_AUTOCOMPLETE_SEARCH_CONTAINER"
                className={classNames(theme.SelectButton, {
                  [theme.ActiveSelectButton]: searchType === 'FIND_A_HOME_TYPE',
                })}
                style={{
                  ...(searchType === 'FIND_A_HOME_TYPE' && {
                    borderColor: activeOptionUnderlineColor,
                  }),
                }}
                onClick={() => {
                  dispatch(
                    reportEvent(
                      'click_banner_home_search_buyer',
                      PARENT_EVENTS.CLICK_BANNER_SEARCH
                    )
                  );
                }}
              >
                {homePageSearchPageLabel}
              </Tabs.Trigger>
              <Tabs.Trigger
                id="TRACK_MY_HOME_TYPE"
                value="TRACK_MY_HOME_TYPE"
                data-hc-name="track-my-home"
                aria-controls="HOME_SUBPAGE_AUTOCOMPLETE_SEARCH_CONTAINER"
                className={classNames(theme.SelectButton, {
                  [theme.ActiveSelectButton]:
                    searchType === 'TRACK_MY_HOME_TYPE',
                })}
                style={{
                  ...(searchType === 'TRACK_MY_HOME_TYPE' && {
                    borderColor: activeOptionUnderlineColor,
                  }),
                }}
                onClick={() => {
                  dispatch(
                    reportEvent(
                      'click_banner_home_search_owner',
                      'click_banner_search'
                    )
                  );
                }}
              >
                {homePageHomeownerPageLabel}
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        )}
        {isMounted ? (
          <AutoCompleteSearchContainer
            id="HOME_SUBPAGE_AUTOCOMPLETE_SEARCH_CONTAINER"
            shouldCalculateMaxHeight
            shouldNotDisableBodyScroll
            hidePlaceResults={searchType === 'TRACK_MY_HOME_TYPE'}
            hideSavedSearches={searchType === 'TRACK_MY_HOME_TYPE'}
            dataHcName={'search-field'}
            theme={theme}
            handleClickSearchButton={() =>
              dispatch(reportEvent('click_search_button_homepage'))
            }
            onInputMouseDown={() => {
              setIsInputFocused(true);
            }}
            handleInputFocus={() => {
              if (searchType === 'FIND_A_HOME_TYPE') {
                dispatch(reportEvent('click_homeowner_search'));
              } else {
                dispatch(searchFieldClick());
              }
            }}
            handleInputBlur={() => {
              setIsInputFocused(false);
            }}
            useBackgroundScreenForBlur
            shouldDisplayCurrentLocationOption={
              searchType === 'FIND_A_HOME_TYPE' && isSmallSize
            }
            clearAndCloseWithOneClick
            placeholder={homePagePlaceholder}
            isHomeownerSearch={searchType === 'TRACK_MY_HOME_TYPE'}
            shouldNavigateToSRP={
              searchType === 'FIND_A_HOME_TYPE' && isSmallSize
            }
          />
        ) : (
          <div className={theme.AutoCompleteSSRPlaceholder} />
        )}
      </HomeSubpageMotionDiv>
      <HomeSubpageMotionDiv
        initial={{ opacity: 1 }}
        animate={animateBrokerAttr}
      >
        <BrokerageAttributionContainer theme={theme} hideBrokerageSection />
      </HomeSubpageMotionDiv>
      <div className={theme.PhotoSection} ref={setPhotoSectionEleCallback}>
        <PhotoColumn
          animate={animatePhotoColumnSlower}
          key="photo-column-1"
          urlsToRender={preloadedImageUrls}
          urls={isSmallSize ? HOME_IMAGES.slice(0, 4) : HOME_IMAGES.slice(0, 2)}
        />
        <PhotoColumn
          animate={
            isSmallSize ? animatePhotoColumnSlower : animatePhotoColumnFaster
          }
          key="photo-column-2"
          urlsToRender={preloadedImageUrls}
          urls={
            isSmallSize
              ? HOME_IMAGES.slice(4, 8)
              : HOME_IMAGES.slice(2, 4).concat(HomeImage9)
          }
        />
        <PhotoColumn
          animate={animatePhotoColumnSlower}
          key="photo-column-3"
          urlsToRender={preloadedImageUrls}
          urls={HOME_IMAGES.slice(4, 6)}
        />
        <PhotoColumn
          animate={animatePhotoColumnFaster}
          key="photo-column-4"
          urlsToRender={preloadedImageUrls}
          urls={HOME_IMAGES.slice(6, 8).concat(HomeImage10)}
        />
      </div>
    </div>
  );
};

export default HomeSubpageSearch;
