import classNames from 'classnames';
import loadable from '@loadable/component';

import theme from '@client/css-modules/HomepageArrowNav.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

const ArrowNav = loadable(
  () => import(/* webpackPrefetch: true */ '@client/inline-svgs/arrow-nav')
);

type Props = {
  isOnDarkBg: boolean;
  isAtEnd: boolean;
  selectedIndex: number;
  onArrowClick: (index: number) => void;
};

export default function HomepageArrowNav({
  isOnDarkBg,
  isAtEnd,
  selectedIndex,
  onArrowClick,
}: Props) {
  function handleOnClick() {
    onArrowClick(isAtEnd ? 0 : selectedIndex + 1);
  }

  return (
    <button
      className={classNames(theme.HomepageArrowNavButton, {
        [theme.InvertColors]: isOnDarkBg,
        [theme.UpArrow]: isAtEnd,
      })}
      aria-label="Homepage Arrow Nav"
      type="button"
      onClick={handleOnClick}
      onKeyDown={onEnterOrSpaceKey(handleOnClick)}
    >
      <ArrowNav />
    </button>
  );
}
