import RouterLink from '@client/components/RouterLink';
import defaultTheme from '@client/css-modules/LinkButton.css';
import classNames from 'classnames';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { View } from '@client/routes/constants';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { CSSProperties } from 'react';

type Props = {
  view: View;
  ariaLabel?: string;
  onClick?: () => void;
  theme: Theme;
  className?: string;
  dataHcName?: string;
  styles?: CSSProperties;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  deemphasized?: boolean;
  children?: React.ReactNode;
};

/* <RouterLink /> styled as a button. For accessibility best practices use this
 * in cases where the button will take you to a different route/page vs perform
 * an action on the current page (in which case <PillButton /> is a better component)
 */
const LinkButton = ({
  children,
  view,
  ariaLabel,
  onClick = () => null,
  theme,
  className = '',
  dataHcName,
  styles,
  secondary,
  tertiary,
  deemphasized,
}: Props) => {
  const {
    pillButtonPrimaryBackgroundColor,
    pillButtonSecondaryBackgroundColor,
    pillButtonTertiaryBackgroundColor,
    pillButtonPrimaryTextColor,
    pillButtonSecondaryTextColor,
    pillButtonTertiaryTextColor,
    pillButtonPrimaryButtonBorderRadius,
    pillButtonSecondaryButtonBorderRadius,
    pillButtonCustomButtonMinWidth,
    pillButtonCustomButtonWidth,
    pillButtonDeemphasizedBackgroundColor,
    pillButtonDeemphasizedTextColor,
  } = useCobrandStyles();

  const customStyles = {
    primary: {
      background: pillButtonPrimaryBackgroundColor,
      color: pillButtonPrimaryTextColor,
      borderRadius: pillButtonPrimaryButtonBorderRadius,
    },
    secondary: {
      background: pillButtonSecondaryBackgroundColor,
      color: pillButtonSecondaryTextColor,
      borderRadius: pillButtonSecondaryButtonBorderRadius,
    },
    tertiary: {
      background: pillButtonTertiaryBackgroundColor,
      color: pillButtonTertiaryTextColor,
      borderRadius:
        tertiary || secondary
          ? pillButtonSecondaryButtonBorderRadius
          : pillButtonPrimaryButtonBorderRadius,
    },
    deemphasized: {
      background: pillButtonDeemphasizedBackgroundColor,
      color: pillButtonDeemphasizedTextColor,
      borderRadius:
        tertiary || secondary
          ? pillButtonSecondaryButtonBorderRadius
          : pillButtonPrimaryButtonBorderRadius,
    },
  };
  let relevantStyles;

  if (secondary) {
    relevantStyles = customStyles.secondary;
  } else if (tertiary) {
    relevantStyles = customStyles.tertiary;
  } else if (deemphasized) {
    relevantStyles = customStyles.deemphasized;
  } else {
    relevantStyles = customStyles.primary;
  }

  return (
    <RouterLink
      className={classNames(theme.LinkButton, { [className]: !!className })}
      style={{
        ...relevantStyles,
        ...styles,
        ...(pillButtonCustomButtonMinWidth && {
          minWidth: pillButtonCustomButtonMinWidth,
        }),
        ...(pillButtonCustomButtonWidth && {
          width: pillButtonCustomButtonWidth,
        }),
      }}
      view={view}
      onClick={onClick}
      {...(ariaLabel ? { ariaLabel: ariaLabel } : {})}
      {...(dataHcName ? { 'data-hc-name': dataHcName } : {})}
    >
      {children}
    </RouterLink>
  );
};

export default themr('ThemedLinkButton', defaultTheme)(LinkButton);
