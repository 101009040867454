import React from 'react';
import { useSelector } from 'react-redux';
import { motion, HTMLMotionProps } from 'framer-motion';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';

type HTMLDivProps = Omit<React.HTMLProps<HTMLDivElement>, 'ref'>;
type MotionDivProps = Omit<HTMLMotionProps<'div'>, keyof HTMLDivProps>;

const HomeSubpageMotionDiv: React.FC<HTMLMotionProps<'div'> & HTMLDivProps> = ({
  children,
  ...props
}) => {
  const motionDivProps = props as MotionDivProps;

  const isFourHundredZoom = useSelector(getIsFourHundredZoom)

  return isFourHundredZoom ? (
    <div {...props}>{children}</div>
  ) : (
    <motion.div {...props} {...motionDivProps}>
      {children}
    </motion.div>
  );
};

export default HomeSubpageMotionDiv;
