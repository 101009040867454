import { useEffect, useState } from 'react';

/**
 * Simple hook used to check if component is mounted, i.e. to ensure that we're not in the
 * server-side-rendering phase or the initial render on the client.
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}
