import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { MotionValue } from 'framer-motion';

import theme from '@client/css-modules/HomeSubpageYourTeamAgent.css';
import { useHomepageElementAnimationMetrics } from '@client/hooks/use-homepage-element-animation-metrics.hooks';
import LinkButton from '@client/components/generic/LinkButton';
import HomeSubpageMotionDiv from '@client/components/HomeSubpageMotionDiv';
import { View } from '@client/routes/constants';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import backgroundImageDesktop from '@client/assets/images/your-team-homepage.png';
import backgroundImageMobile from '@client/assets/images/your-team-homepage-mobile.png';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';

type Props = {
  scrollPosition: MotionValue<number>;
};

const STARTING_POSITION_OFFSET = 250;
const BUTTON_COPY = 'Learn More';
const HEADER_COPY = "Need help finding an agent? We'll connect you.";
const DESCRIPTION_COPY =
  'We can help pair you with the right agent for your real estate needs. Let our team help make locating the best agent easy and smooth.';

const HomeSubpageYourTeamAgent: React.FC<Props> = ({ scrollPosition }) => {
  const reportYourTeamEvent = useReportYourTeamEvent();
  const isSmallScreenSize = useSelector(getIsSmallSize);
  const { homepageDarkBackground, homepageDarkBgTextColor } =
    useCobrandStyles();

  const bgImage = isSmallScreenSize
    ? backgroundImageMobile
    : backgroundImageDesktop;

  const ref = useRef<HTMLDivElement | null>(null);
  const { y, opacity } = useHomepageElementAnimationMetrics(
    ref,
    scrollPosition,
    STARTING_POSITION_OFFSET
  );

  return (
    <div className={theme.HomeSubpageYourTeamAgent} ref={ref}>
      <HomeSubpageMotionDiv
        initial={{ y: 0 }}
        animate={{
          y,
          opacity,
        }}
        transition={{
          duration: 0.1,
          delay: 0,
          type: 'spring',
          mass: 0.1,
          damping: 18,
          stiffness: 250,
        }}
        className={theme.AnimationWrapper}
      >
        <div className={theme.ContentWrapper}>
          <img
            alt="a hand writing with a pen"
            className={theme.FindAnAgentImage}
            src={bgImage}
          />
          <div
            className={theme.ContentCard}
            style={{
              backgroundColor: homepageDarkBackground,
              color: homepageDarkBgTextColor,
            }}
          >
            <h2 className={theme.CardHeader}>{HEADER_COPY}</h2>
            <p className={theme.CardDescription}>{DESCRIPTION_COPY}</p>
            <div className={theme.CTAButtonWrapper}>
              <LinkButton
                className={theme.CTAButton}
                view={View.CONCIERGE_TEAM}
                ariaLabel="Learn more about finding an agent"
                dataHcName="find-an-agent-cta"
                onClick={() => {
                  reportYourTeamEvent(
                    'click_nav_homepage_find_agent_component',
                    {
                      buttonCopy: BUTTON_COPY,
                      headerCopy: HEADER_COPY,
                      descriptionCopy: DESCRIPTION_COPY,
                    }
                  );
                }}
              >
                {BUTTON_COPY}
              </LinkButton>
            </div>
          </div>
        </div>
      </HomeSubpageMotionDiv>
    </div>
  );
};

export default HomeSubpageYourTeamAgent;
